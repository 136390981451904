<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
          class="sosty-logo-header"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
            class="sosty-login-image"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-col v-if="typePassword">  
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Olvidaste tu Contraseña? 🔒
            </b-card-title>

            <b-card-text class="mb-2">
              Por favor, digita tu email y te enviaremos un código de confirmación.
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                </b-form-group>
                <!-- submit buttons -->
                <b-button v-if="!processing" type="submit" variant="primary" block @click="sendCode">
                  Enviar Código
                </b-button>

                <b-button v-if="processing" type="submit" variant="primary" disabled block>
                  <b-spinner small label="Cargando..." />
                </b-button>
              </b-form>
            </validation-observer>
            <p class="text-center mt-2">
              <b-link :to="{name:'login'}">
                <feather-icon icon="ChevronLeftIcon" /> Volver al login
              </b-link>
            </p>
          </b-col>

          <b-col v-if="sendedCode">
          <!-- <b-col> -->
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Digita código de validación 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              Por favor, digita el código de confirmación que recibiste:
            </b-card-text>
            <b-form-group
                label="Código:"
                label-for="code"
              >
                <b-form-input 
                  type="number"                 
                  v-model="passcode"                  
                  placeholder="Número de 4 digitos"
                />
            </b-form-group>
            <b-button v-if="!processing" type="submit" variant="primary" block @click="validatePasswordRecoveryCode">
              Validar Código
            </b-button>
            <b-button v-if="processing" type="submit" variant="primary" disabled block>
                <b-spinner small label="Cargando..." />
            </b-button>
          </b-col>

          <b-col v-if="confirmCode">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Digita tu nueva contraseña 🔒
            </b-card-title>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Contraseña</label>
              </div>
              <b-input-group
                  class="input-group-merge">
                <b-form-input  
                  id="login-password"                  
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>              
              <div class="d-flex justify-content-between mt-2">
                <label for="login-password">Confirmar Contraseña</label>
              </div>
              <b-input-group
                  class="input-group-merge">
                <b-form-input
                  id="login-passwordConfirmed"                    
                  v-model="passwordConfirmed"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-passwordConfirmed"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button v-if="!processing" type="submit" variant="primary" block @click="setNewPassword">
              Guardar Contraseña
            </b-button>
            <b-button v-if="processing" type="submit" variant="primary" disabled block>
              <b-spinner small label="Cargando..." />
            </b-button>
          </b-col>

          <b-col v-if="newLogin">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Dirigite a la página de Login
            </b-card-title>
            <b-button v-if="!processing" type="submit" variant="primary" block @click="goToLogin">
              Iniciar Sesión
            </b-button>
            <b-button v-if="processing" type="submit" variant="primary" disabled block>
              <b-spinner small label="Cargando..." />
            </b-button>
          </b-col>
        </b-col>

      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/sosty-login.png'),
      processing: false,
      passcode: null,
      typePassword: true,
      sendedCode: false,
      password: null,
      passwordConfirmed: null,
      confirmCode: false,
      newLogin: false,
    }
  },

  watch : {
    password: function(val) { this.password = val; }, 
    passwordConfirmed: function(val) { this.passwordConfirmed = val; }, 
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {    
    sendLink() {        
        this.processing = true;
        axios.get('/User/RequestPasswordRecoveryEmail', { params: { email: this.userEmail }})
        .then(response => {
          this.processing = false;          
          if(response.data) {          
            this.$bvToast.toast("Hemos enviado un email con tu nueva contraseña", { title: 'Email Enviado', variant: 'success', solid: true, })     
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }          
        })
        .catch(error => {
          this.processing = false;
          if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
          } else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
          }
        })
    },
    sendCode() {        
        this.processing = true;
        axios.get('/User/SendPasswordRecoveryCode', { params: { email: this.userEmail }})
        .then(response => {
          this.processing = false;          
          if(response.data) {
            this.typePassword = false
            this.sendedCode = true            
            this.$bvToast.toast("Hemos enviado un código de confirmación a tu correo", { title: 'Email Enviado', variant: 'success', solid: true, })     
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }          
        })
        .catch(error => {
          this.processing = false;
          if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
          } else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
          }
        })
    },
    validatePasswordRecoveryCode(){
      this.processing = true;
        axios.get('/User/ValidatePasswordRecoveryCode', { params: { email: this.userEmail, code: this.passcode }})
        .then( response => {
          this.processing = false;
          if(response.data) {  
            this.$bvToast.toast("Validación exitosa, Digita tu nueva contraseña", { title: 'Email Enviado', variant: 'success', solid: true, })            
            this.confirmCode = true  
            this.sendedCode = false
          } else {            
            this.$bvToast.toast("Código NO coincide, Intenta Nuevamente", { title: 'Email', variant: 'danger', solid: true, })            
          }          
        })      
    },
    setNewPassword(){      
      if(this.password != this.passwordConfirmed ){
        this.$bvToast.toast("Tus contraseñas no coinciden", { title: 'Error', variant: 'danger', solid: true, });
      return;
      }
      else{        
        axios.get('/User/setNewPassword', { params: { email: this.userEmail, newPassword: this.passwordConfirmed }})
        .then(response => {
          if(response){
            this.$bvToast.toast("Tu contraseña ha sido guardada, porfavor logueate nuevamente", { title: 'Email Enviado', variant: 'success', solid: true, })
            this.confirmCode = false
            this.newLogin = true
          }else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })
          }
          console.log('password guardado')                  
        })
      }      
    },
    goToLogin(){
      this.$router.replace({path: 'login'});
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
